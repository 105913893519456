"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const functions_1 = require("../../shared/functions");
class YotiSession {
    constructor() {
        this.active = false;
        this.isProcessingYotiSession = !!document.cookie.match(/yoti-session=true/);
    }
    get isProcessingYotiSession() {
        return this._isProcessingYotiSession;
    }
    set isProcessingYotiSession(value) {
        this._isProcessingYotiSession = value;
        if (this._isProcessingYotiSession) {
            this.monitorYotiSession();
        }
    }
    async monitorYotiSession() {
        if (this.monitorYotiSessionRunning) {
            return;
        }
        this.monitorYotiSessionRunning = true;
        while (this.isProcessingYotiSession) {
            try {
                await this.checkTransaction();
            }
            finally {
                await (0, functions_1.sleep)(1000);
            }
        }
        this.monitorYotiSessionRunning = false;
    }
    async checkTransaction() {
        try {
            const response = await fetch("/yoti/_status");
            if (!response.ok) {
                return;
            }
            const result = await response.json();
            if (result?.status === "completed") {
                document.cookie = "yoti-session=false; path=/; samesite=strict;";
                console.info("reload: yoti completed");
                document.location.reload();
            }
        }
        finally {
            // pass
        }
    }
}
exports.default = new YotiSession();
