"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18PinFieldTemplate = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const fast_foundation_1 = require("@microsoft/fast-foundation");
/**
 * The template for the {@link @microsoft/fast-foundation#(TextField:class)} component.
 * @public
 */
const M18PinFieldTemplate = (context, definition) => (0, fast_element_1.html) `
  <template
    class="
            ${(x) => (x.readOnly ? "readonly" : "")}
        "
  >
    <label
      part="label"
      for="control"
      class="${(x) => x.defaultSlottedNodes && x.defaultSlottedNodes.length
    ? "label"
    : "label label__hidden"}"
    >
      <slot ${(0, fast_element_1.slotted)("defaultSlottedNodes")}></slot>
    </label>
    <div class="root" part="root">
      ${(0, fast_foundation_1.startSlotTemplate)(context, definition)}
      <input
        id="control"
        @input="${(x) => x.handleTextInput()}"
        @change="${(x) => x.handleChange()}"
        @blur="${(x) => x.handleBlur()}"
        ?required="${(x) => x.required}"
        ?disabled="${(x) => x.disabled}"
        ?readonly="${(x) => x.readOnly}"
        :value="${(x) => x.value}"
        type="hidden"
        aria-atomic="${(x) => x.ariaAtomic}"
        aria-busy="${(x) => x.ariaBusy}"
        aria-controls="${(x) => x.ariaControls}"
        aria-current="${(x) => x.ariaCurrent}"
        aria-describedby="${(x) => x.ariaDescribedby}"
        aria-details="${(x) => x.ariaDetails}"
        aria-disabled="${(x) => x.ariaDisabled}"
        aria-errormessage="${(x) => x.ariaErrormessage}"
        aria-flowto="${(x) => x.ariaFlowto}"
        aria-haspopup="${(x) => x.ariaHaspopup}"
        aria-hidden="${(x) => x.ariaHidden}"
        aria-invalid="${(x) => x.ariaInvalid}"
        aria-keyshortcuts="${(x) => x.ariaKeyshortcuts}"
        aria-label="${(x) => x.ariaLabel}"
        aria-labelledby="${(x) => x.ariaLabelledby}"
        aria-live="${(x) => x.ariaLive}"
        aria-owns="${(x) => x.ariaOwns}"
        aria-relevant="${(x) => x.ariaRelevant}"
        aria-roledescription="${(x) => x.ariaRoledescription}"
        ${(0, fast_element_1.ref)("control")}
      />
      <div class="controls" part="controls">
        ${(0, fast_element_1.repeat)((x) => Array(x.length).fill(x), (0, fast_element_1.html) `
            <div part="control" class="control ${(x) => `control-${x.length}`}">
              <input
                part="field"
                @paste="${(x, c) => x.handlePaste(c.event)}"
                @focus="${(x, c) => x.handleFocus(c.event)}"
                @blur="${(x) => x.handleInputBlur()}"
                type="text"
                @input="${(x, c) => x.inputHandler(c.event)}"
                inputmode="numeric"
                @keydown="${(x, c) => x.keydownHandler(c.event)}"
                maxlength="1"
                placeholder="${(x) => x.placeholder}"
                name="field-${(x, c) => c.index}"
              />
            </div>
          `, { positioning: true })}
      </div>
      ${(0, fast_foundation_1.endSlotTemplate)(context, definition)}
    </div>
    <span part="error" :innerText="${(x) => x.error}"></span>
  </template>
`;
exports.M18PinFieldTemplate = M18PinFieldTemplate;
