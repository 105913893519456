"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18Button = void 0;
const fast_components_1 = require("@microsoft/fast-components");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const m18_button_styles_1 = require("./m18-button.styles");
const helpers_1 = require("../helpers");
const baseName = "button";
exports.m18Button = fast_components_1.Button.compose({
    baseName,
    template: (0, helpers_1.template)(fast_foundation_1.buttonTemplate, baseName),
    styles: m18_button_styles_1.M18ButtonStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
