"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatMonitor = void 0;
const onVisibilityChanged = (data) => {
    const element = document.getElementById("chat-widget-container");
    switch (data.visibility) {
        case "maximized":
            if (!element?.classList.contains("maximized")) {
                element?.classList.add("maximized");
            }
            break;
        case "minimized":
            element?.classList.remove("maximized");
            break;
        case "hidden":
            element?.classList.remove("maximized");
            break;
    }
};
const onAvailabilityChanged = (data) => {
    const element = document.getElementById("chat-widget-container");
    switch (data.availability) {
        case "online":
            if (!element?.classList.contains("online")) {
                element?.classList.add("online");
            }
            break;
        default:
            element?.classList.remove("online");
    }
};
let monitoring = false;
const chatMonitor = () => {
    if (monitoring) {
        return;
    }
    monitoring = true;
    document.addEventListener("DOMContentLoaded", () => {
        const LiveChatWidget = global.LiveChatWidget;
        LiveChatWidget?.on("visibility_changed", onVisibilityChanged);
        LiveChatWidget?.on("availability_changed", onAvailabilityChanged);
        LiveChatWidget.on("ready", () => {
            onVisibilityChanged({
                visibility: LiveChatWidget.get("state").visibility,
            });
            onAvailabilityChanged({
                availability: LiveChatWidget.get("state").availability,
            });
        });
    });
};
exports.chatMonitor = chatMonitor;
