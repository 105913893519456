"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class StraceoTransaction {
    constructor() {
        this.active = false;
        document.addEventListener("DOMContentLoaded", () => {
            if (document.body?.hasAttribute("no-transaction-check")) {
                return;
            }
            this.isProcessingTransaction = !!document.cookie.match(/straceo-transaction=true/);
        });
        navigator.serviceWorker?.addEventListener("message", (event) => {
            if (event.type === "straceo-transaction-done") {
                console.info("strace: straceo-transaction-done");
                this.popup?.close();
                document.location.reload();
            }
            else {
                console.info("unknown message", event);
            }
        });
        window.addEventListener("message", (event) => {
            if (event.origin === document.location.origin) {
                if (event.data === "next") {
                    this.popup?.close();
                }
                else if (event.data === "initialized") {
                    this.isProcessingTransaction = true;
                }
            }
        });
    }
    get isProcessingTransaction() {
        return this._isProcessingTransaction;
    }
    set isProcessingTransaction(value) {
        if (this._isProcessingTransaction === value) {
            return;
        }
        this._isProcessingTransaction = value;
        if (this._isProcessingTransaction) {
            this.pingServiceWorkerInterval = setInterval(() => {
                navigator.serviceWorker?.controller.postMessage({
                    type: "ping",
                });
            }, 1000);
            navigator.serviceWorker?.controller.postMessage({
                type: "start-monitor-straceo-transaction",
            });
        }
        else {
            clearInterval(this.pingServiceWorkerInterval);
            navigator.serviceWorker?.controller.postMessage({
                type: "stop-monitor-straceo-transaction",
            });
            this.popup?.close();
        }
        if (this.transactionButton) {
            const el = this.transactionButton.getElementsByClassName("text").item(0) ||
                this.transactionButton;
            el.innerHTML = this.transactionButton.getAttribute(this._isProcessingTransaction
                ? "data-text-processing"
                : "data-text-idle");
        }
    }
    setActive() {
        if (this.active) {
            return;
        }
        this.active = true;
        this.transactionButton = document.getElementById("straceo-transaction-button");
        this.transactionButton.addEventListener("click", () => {
            console.info("straceo: click");
            if (this.popup || this.isProcessingTransaction) {
                console.info("straceo: cancelTransaction");
                this.cancelTransaction();
            }
            else {
                this.initTransaction();
            }
        });
        let last = !!document.cookie.match(/straceo-transaction=true/);
        let stableCount = 0;
        setInterval(() => {
            const current = !!document.cookie.match(/straceo-transaction=true/);
            if (current === last) {
                stableCount++;
                if (stableCount > 3) {
                    this.isProcessingTransaction = last;
                }
            }
            else {
                stableCount = 0;
            }
            last = current;
        }, 500);
    }
    initTransaction() {
        console.info("straceo: initTransaction");
        const width = 670;
        const height = 875;
        const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;
        const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
        this.popup = window.open("/_capture-payment/straceo/init", StraceoTransaction.popupName, `height=${height}, width=${width}, top=${y}, left=${x}`);
        console.info("straceo: initTransaction, popup:", this.popup);
    }
    async cancelTransaction() {
        console.info("strace: cancelTransaction");
        try {
            const popup = this.popup;
            this.popup = void 0;
            this.isProcessingTransaction = false;
            const response = await fetch("/_capture-payment/straceo/cancel", {
                method: "POST",
            });
            if (!response.ok) {
                console.error("cancel transaction failed with status " + response.status);
                return;
            }
            console.info("strace: cancelTransaction close");
            popup?.close();
        }
        catch (err) {
            console.error(err);
        }
    }
}
StraceoTransaction.popupName = "StraceoTransactionPopup";
exports.default = new StraceoTransaction();
