"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomString = exports.trimObjectValuesInPlace = exports.isString = exports.sleep = exports.noop = exports.randomInt = exports.pErr = void 0;
const crypto_1 = require("crypto");
const pErr = (err) => {
    if (err) {
        console.error(err);
    }
};
exports.pErr = pErr;
const randomInt = () => {
    return Math.floor(Math.random() * 1000000000000);
};
exports.randomInt = randomInt;
const safeRandomBytes = (len) => {
    let retry = 0;
    while (retry < 10) {
        try {
            return (0, crypto_1.randomBytes)(len);
        }
        catch (e) {
            retry++;
        }
    }
};
const noop = () => void 0;
exports.noop = noop;
const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));
exports.sleep = sleep;
function isString(val) {
    return Object.prototype.toString.call(val) === "[object String]";
}
exports.isString = isString;
const trimObjectValuesInPlace = (obj) => {
    if (obj instanceof Object) {
        for (const key of Object.keys(obj)) {
            if (isString(obj[key])) {
                obj[key] = obj[key].trim();
            }
        }
    }
    return obj;
};
exports.trimObjectValuesInPlace = trimObjectValuesInPlace;
const randomString = (len, limit = new RegExp("")) => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-.~_".replace(limit, "");
    const charsLen = chars.length;
    const maxByte = 256 - (256 % charsLen);
    let string = "";
    while (len > 0) {
        const buf = safeRandomBytes(Math.ceil((len * 256) / maxByte));
        for (let i = 0; i < buf.length && len > 0; i++) {
            const randomByte = buf.readUInt8(i);
            if (randomByte < maxByte) {
                string += chars.charAt(randomByte % charsLen);
                len--;
            }
        }
    }
    return string;
};
exports.randomString = randomString;
