"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18LangTemplate = void 0;
const fast_element_1 = require("@microsoft/fast-element");
/**
 * The template for the {@link @microsoft/fast-foundation#(TextField:class)} component.
 * @public
 */
exports.M18LangTemplate = (0, fast_element_1.html) `
  <template>
    <div
      class="wrapper"
      @mouseover="${(x) => x.handleMouseOver()}"
      @mouseleave="${(x) => x.handleMouseLeave()}"
      @touchend="${(x) => x.handleTap()}"
    >
      <div class="selected">${(x) => x.language.name}</div>
      <div class="menu">
        <ul>
          ${(0, fast_element_1.repeat)((x) => x.languages, (0, fast_element_1.html) `
              <li
                class="item ${(x, y) => x === y.parent.language ? "item--selected" : ""}"
                @click="${(x, y) => y.parent.handleClick(x)}"
              >
                <i>&#xe800;</i>${(x) => x.name}
              </li>
            `, { positioning: true })}
        </ul>
      </div>
    </div>
  </template>
`;
