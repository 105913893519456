"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18Lang = exports.M18Lang = void 0;
const tslib_1 = require("tslib");
const fast_element_1 = require("@microsoft/fast-element");
const m18_lang_template_1 = require("./m18-lang.template");
const m18_lang_styles_1 = require("./m18-lang.styles");
const fast_foundation_1 = require("@microsoft/fast-foundation");
class M18Lang extends fast_foundation_1.FoundationElement {
    constructor() {
        super(...arguments);
        this.languages = [];
    }
    get language() {
        return this.languages[0];
    }
    handleMouseOver() {
        const menu = this.shadowRoot.querySelector(".menu");
        clearTimeout(this.closeMenuTimeout);
        const root = this.shadowRoot.querySelector(".wrapper");
        if (root.classList.contains("touch-device")) {
            return;
        }
        if (!menu.classList.contains("menu--open")) {
            menu.classList.add("menu--open");
        }
        menu.style.top = "2rem";
        menu.style.right = "auto";
        menu.style.bottom = "auto";
        menu.style.left = "50%";
        menu.style.transform = "translateX(-50%)";
        const { x, y } = menu.getBoundingClientRect();
        if (x + menu.offsetWidth > document.documentElement.clientWidth) {
            menu.style.left = "auto";
            menu.style.transform = "none";
            menu.style.right = "0px";
        }
        if (y + menu.offsetHeight > document.documentElement.clientHeight) {
            menu.style.top = "auto";
            menu.style.bottom = "2rem";
        }
    }
    handleMouseLeave() {
        this.closeMenuTimeout = window.setTimeout(() => {
            const menu = this.shadowRoot.querySelector(".menu");
            menu.classList.remove("menu--open");
        }, 350);
    }
    handleClick(lng) {
        const url = new URL(document.location.href);
        url.searchParams.set("lng", lng.code);
        document.location.href = url.href;
    }
    handleTap() {
        const root = this.shadowRoot.querySelector(".wrapper");
        if (root.classList.contains("touch-device")) {
            return true;
        }
        root.classList.add("touch-device");
        return false;
    }
}
M18Lang.langConverter = {
    toView: (value) => value.toString(),
    fromView: (value) => value?.split?.("|").map((v) => {
        const [code, name] = v.split(",");
        return { code, name };
    }),
};
tslib_1.__decorate([
    (0, fast_element_1.attr)({ converter: M18Lang.langConverter }),
    tslib_1.__metadata("design:type", Array)
], M18Lang.prototype, "languages", void 0);
exports.M18Lang = M18Lang;
const baseName = "lang";
exports.m18Lang = M18Lang.compose({
    baseName,
    template: m18_lang_template_1.M18LangTemplate,
    styles: m18_lang_styles_1.M18LangStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
