"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.provideM18DesignSystem = void 0;
const fast_foundation_1 = require("@microsoft/fast-foundation");
const fast_components_1 = require("@microsoft/fast-components");
const fast_colors_1 = require("@microsoft/fast-colors");
const fast_components_2 = require("@microsoft/fast-components");
const accent = "#21AD7B";
fast_components_1.accentPalette.withDefault(fast_components_2.PaletteRGB.from(fast_components_1.SwatchRGB.from((0, fast_colors_1.parseColorHexRGB)(accent))));
const neutral = "#FBFBFB";
fast_components_1.neutralPalette.withDefault(fast_components_2.PaletteRGB.from(fast_components_1.SwatchRGB.from((0, fast_colors_1.parseColorHexRGB)(neutral))));
fast_components_1.bodyFont.withDefault(`"DM Sans", sans-serif`);
fast_components_1.controlCornerRadius.withDefault(8);
fast_components_1.density.withDefault(2);
fast_components_1.neutralFillInputRestDelta.withDefault(-4);
fast_components_1.baseLayerLuminance.withDefault(fast_components_1.StandardLuminance.LightMode);
fast_components_1.fillColor.withDefault(fast_components_1.SwatchRGB.from((0, fast_colors_1.parseColorHexRGB)("#ffffff")));
function provideM18DesignSystem(element) {
    return fast_foundation_1.DesignSystem.getOrCreate(element)
        .withPrefix("m18")
        .withDesignTokenRoot(element);
}
exports.provideM18DesignSystem = provideM18DesignSystem;
