"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepValidateEmail = void 0;
const i18n_1 = require("./i18n");
const functions_1 = require("../../shared/functions");
const cacheResponses = new Map();
const parseReason = (str) => {
    const res = str.match(/.*\(([0-9]+)\.([0-9]+)\)/);
    if (res) {
        return [~~res[1], ~~res[2]];
    }
    return [0, 0];
};
const wellKnownDarkMailDomains = ["yopmail.com", "yopmail.fr", "example.com"];
let error = 0;
const deepValidateEmail = async (str) => {
    let resolved = false;
    str = str.trim();
    const domain = str.split("@").pop();
    if (wellKnownDarkMailDomains.includes(domain)) {
        error += 1;
        await (0, functions_1.sleep)(error * 1000);
        const response = { str, valid: false };
        response.error = (0, i18n_1.translate)("email.dark_domain");
        return response;
    }
    return await Promise.race([
        (async () => {
            const response = { str, valid: true };
            try {
                if (cacheResponses.has(str)) {
                    return cacheResponses.get(str);
                }
                const result = await fetch(`https://api.dmood.org/1/validate/` + encodeURIComponent(str));
                if (result.ok) {
                    const data = (await result.json());
                    response.valid = data.status !== "deny";
                    if (!response.valid) {
                        error += 1;
                        await (0, functions_1.sleep)(error * 1000);
                        const [mainError, subError] = parseReason(data.reason);
                        if (mainError === 1) {
                            response.error = (0, i18n_1.translate)("email.invalid_email");
                        }
                        else if (mainError === 2) {
                            response.error = (0, i18n_1.translate)("email.dark_domain");
                        }
                        else {
                            response.error = (0, i18n_1.translate)("email.rejected");
                        }
                    }
                }
                resolved = true;
                cacheResponses.set(str, response);
                return response;
            }
            catch (e) {
                console.error(e);
                resolved = true;
                return { str, valid: true };
            }
        })(),
        new Promise((resolve) => {
            setTimeout(() => {
                resolve({ str, valid: true });
                if (!resolved) {
                    console.error("Timeout email validation");
                }
            }, 5000 + (error + 1) * 1000);
        }),
    ]);
};
exports.deepValidateEmail = deepValidateEmail;
