"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18Radio = void 0;
const fast_components_1 = require("@microsoft/fast-components");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const helpers_1 = require("./helpers");
const baseName = "radio";
exports.m18Radio = fast_components_1.Radio.compose({
    baseName,
    template: (0, helpers_1.template)(fast_foundation_1.radioTemplate, baseName),
    styles: (0, helpers_1.styles)(fast_components_1.radioStyles, baseName),
    shadowOptions: {
        delegatesFocus: true,
    },
    checkedIndicator: `
  <div part="checked-indicator" class="checked-indicator"></div>
`,
});
