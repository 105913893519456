"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18FederationTemplate = void 0;
const fast_element_1 = require("@microsoft/fast-element");
/**
 * The template for the {@link @microsoft/fast-foundation#(Anchor:class)} component.
 * @public
 */
exports.M18FederationTemplate = (0, fast_element_1.html) `
  <a
    class="control"
    part="control"
    href="${(x) => x.href}"
    target="${(x) => x.target}"
  >
    <span class="content" part="content">
      <span class="icon">${(x) => x.iconCharacter}</span>
      <slot></slot>
    </span>
  </a>
`;
