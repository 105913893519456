"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const fetchWithTimeout = (input, init) => {
    if (init?.timeout) {
        const controller = new AbortController();
        const signal = controller.signal;
        const timeout = init.timeout;
        delete init.timeout;
        const fetchPromise = fetch(input, Object.assign({}, init, { signal }));
        const timer = setTimeout(() => controller.abort(), timeout);
        return fetchPromise.finally(() => {
            clearTimeout(timer);
        });
    }
    else {
        return fetch(input, init);
    }
};
exports.default = fetchWithTimeout;
