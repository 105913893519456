"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18ButtonStyles = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const fast_web_utilities_1 = require("@microsoft/fast-web-utilities");
const styles_1 = require("../styles");
exports.M18ButtonStyles = (0, fast_element_1.css) `
  ${styles_1.BaseButtonStyles}
  ${styles_1.HypertextStyles}
  ${styles_1.HypertextNeutralStyles}
  ${styles_1.AccentButtonStyles}
  ${styles_1.LightweightButtonStyles}
  ${styles_1.OutlineButtonStyles}
  ${styles_1.StealthButtonStyles}
`.withBehaviors((0, fast_foundation_1.forcedColorsStylesheetBehavior)((0, fast_element_1.css) `
      :host([disabled]),
      :host([disabled]) .control {
        forced-color-adjust: none;
        background: ${fast_web_utilities_1.SystemColors.ButtonFace};
        border-color: ${fast_web_utilities_1.SystemColors.GrayText};
        color: ${fast_web_utilities_1.SystemColors.GrayText};
        cursor: ${fast_foundation_1.disabledCursor};
        opacity: 1;
      }
      :host([appearance="accent"]) .control {
        forced-color-adjust: none;
        background: ${fast_web_utilities_1.SystemColors.Highlight};
        color: ${fast_web_utilities_1.SystemColors.HighlightText};
      }

      :host([appearance="accent"]) .control:hover {
        background: ${fast_web_utilities_1.SystemColors.HighlightText};
        border-color: ${fast_web_utilities_1.SystemColors.Highlight};
        color: ${fast_web_utilities_1.SystemColors.Highlight};
      }

      :host([appearance="accent"]:${fast_foundation_1.focusVisible}) .control {
        border-color: ${fast_web_utilities_1.SystemColors.ButtonText};
        box-shadow: 0 0 0 2px ${fast_web_utilities_1.SystemColors.HighlightText} inset;
      }

      :host([appearance="accent"][disabled]) .control,
      :host([appearance="accent"][disabled]) .control:hover {
        background: ${fast_web_utilities_1.SystemColors.ButtonFace};
        border-color: ${fast_web_utilities_1.SystemColors.GrayText};
        color: ${fast_web_utilities_1.SystemColors.GrayText};
      }
      :host([appearance="lightweight"]) .control:hover {
        forced-color-adjust: none;
        color: ${fast_web_utilities_1.SystemColors.Highlight};
      }

      :host([appearance="lightweight"]) .control:hover .content::before {
        background: ${fast_web_utilities_1.SystemColors.Highlight};
      }

      :host([appearance="lightweight"][disabled]) .control {
        forced-color-adjust: none;
        color: ${fast_web_utilities_1.SystemColors.GrayText};
      }

      :host([appearance="lightweight"][disabled])
        .control:hover
        .content::before {
        background: none;
      }
      :host([appearance="outline"][disabled]) .control {
        border-color: ${fast_web_utilities_1.SystemColors.GrayText};
      }
      :host([appearance="stealth"]) .control {
        forced-color-adjust: none;
        background: none;
        border-color: transparent;
        color: ${fast_web_utilities_1.SystemColors.ButtonText};
        fill: currentcolor;
      }
      :host([appearance="stealth"]) .control:hover,
            :host([appearance="stealth"]:${fast_foundation_1.focusVisible}) .control {
        background: ${fast_web_utilities_1.SystemColors.Highlight};
        border-color: ${fast_web_utilities_1.SystemColors.Highlight};
        color: ${fast_web_utilities_1.SystemColors.HighlightText};
      }
      :host([appearance="stealth"][disabled]) .control {
        background: none;
        border-color: transparent;
        color: ${fast_web_utilities_1.SystemColors.GrayText};
      }
    `));
