"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = exports.template = void 0;
const template = (fn, baseName) => {
    return (context, def) => {
        return fn(context, { ...def, baseName });
    };
};
exports.template = template;
const styles = (fn, baseName) => {
    return (context, def) => {
        return fn(context, { ...def, baseName });
    };
};
exports.styles = styles;
