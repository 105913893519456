"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18Federation = exports.M18Federation = void 0;
const tslib_1 = require("tslib");
const fast_element_1 = require("@microsoft/fast-element");
const m18_federation_template_1 = require("./m18-federation.template");
const m18_federation_styles_1 = require("./m18-federation.styles");
const fast_colors_1 = require("@microsoft/fast-colors");
const fast_foundation_1 = require("@microsoft/fast-foundation");
class M18Federation extends fast_foundation_1.FoundationElement {
    connectedCallback() {
        super.connectedCallback();
        const color = (0, fast_colors_1.parseColorHexRGB)(this.color ?? "") ||
            (0, fast_colors_1.parseColorHexRGB)(this.style.backgroundColor) ||
            (0, fast_colors_1.parseColorHexRGB)("#21AD7B");
        this.style.setProperty("--font-color", "#FFF");
        this.style.setProperty("--provider-fill-rest", (0, fast_colors_1.darkenViaLAB)((0, fast_colors_1.desaturateViaLCH)(color, 3), 2).toStringHexRGB());
        this.style.setProperty("--provider-fill-hover", (0, fast_colors_1.darkenViaLAB)(color, 0.3).toStringHexRGB());
        this.style.setProperty("--provider-neutral-focus-inner", color.toStringHexRGB());
        this.style.setProperty("--provider-fill-rest", color.toStringHexRGB());
    }
    get iconCharacter() {
        let character = "";
        if (this.icon?.match(/#(.+);/g)) {
            character = this.icon?.replace(/#(.+);/g, function (_match, dec) {
                return String.fromCharCode(dec);
            });
        }
        else if (this.icon) {
            character = String.fromCharCode(Number(this.icon));
        }
        return character;
    }
}
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18Federation.prototype, "href", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18Federation.prototype, "type", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18Federation.prototype, "color", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18Federation.prototype, "icon", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18Federation.prototype, "target", void 0);
exports.M18Federation = M18Federation;
const baseName = "federation";
exports.m18Federation = M18Federation.compose({
    baseName,
    template: m18_federation_template_1.M18FederationTemplate,
    styles: m18_federation_styles_1.M18FederationStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
