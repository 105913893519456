"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18AnchorStyles = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const index_1 = require("../styles/index");
exports.M18AnchorStyles = (0, fast_element_1.css) `
  ${index_1.BaseButtonStyles}
  ${index_1.AccentButtonStyles}
    ${index_1.HypertextStyles}
    ${index_1.LightweightButtonStyles}
    ${index_1.OutlineButtonStyles}
    ${index_1.StealthButtonStyles}
`;
