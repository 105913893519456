"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18TextField = exports.M18TextField = void 0;
const tslib_1 = require("tslib");
const fast_element_1 = require("@microsoft/fast-element");
const fast_components_1 = require("@microsoft/fast-components");
const m18_text_field_template_1 = require("./m18-text-field.template");
const m18_text_field_styles_1 = require("./m18-text-field.styles");
class M18TextField extends fast_components_1.TextField {
    constructor() {
        super();
        this.error = "";
        this.handleTextInput = (e) => {
            super.handleTextInput();
        };
        this.keydownHandler = (e) => {
            if (!this.inputPattern) {
                return true;
            }
            if (this.control.selectionStart === 0 &&
                this.control.selectionEnd === this.control.value.length) {
                return true;
            }
            const position = this.control.selectionStart;
            if (e.key === "Backspace") {
                if (position === this.control.value.length) {
                    return true;
                }
                const start = Math.max(0, this.control.selectionStart - 1);
                this.control.setSelectionRange(start, start);
                return false;
            }
            if (e.key === "Delete") {
                if (position >= this.control.value.length - 1) {
                    this.control.value = this.control.value.trimRight();
                    return true;
                }
                this.setCharacterAt(position, this.inputPattern[position] === "#" ? " " : this.inputPattern[position]);
                return false;
            }
            if (e.key === "Backspace") {
                this.setCharacterAt(position - 1, " ");
                return false;
            }
            return true;
        };
        this.keypressHandler = (e) => {
            if (this.inputPattern && !e.ctrlKey && e.key) {
                const position = this.control.selectionStart;
                // We are at the end and there is no more space anyway
                if (position === this.inputPattern.length) {
                    return false;
                }
                if (this.control.selectionStart === 0 &&
                    this.control.selectionEnd === this.control.value.length) {
                    this.control.value = "";
                }
                const char = e.key;
                const isNumber = !!char.match(/[0-9]/);
                if (this.inputPattern[position] === "#" && isNumber) {
                    this.setCharacterAt(position, char);
                }
                else if (this.inputPattern[position] !== "#") {
                    this.setCharacterAt(position, this.inputPattern[position]);
                    if (isNumber) {
                        this.setCharacterAt(position + 1, char);
                    }
                }
                else {
                    if (!isNumber && char === this.control.value[position - 1]) {
                        return false;
                    }
                    const patternElements = this.inputPattern.split(/[^#]/g);
                    this.control.value = this.control.value
                        .split(/[^0-9]/g)
                        .map((value, i) => value.padStart(patternElements[i].length, "0"))
                        .reduce((res, part, i) => {
                        res += part;
                        res += this.inputPattern[res.length] ?? "";
                        return res;
                    }, "");
                    return false;
                }
                if (this.inputPattern[position + 1] &&
                    this.inputPattern[position + 1] !== "#") {
                    this.setCharacterAt(position + 1, this.inputPattern[position + 1]);
                }
                return false;
            }
            return true;
        };
        this.setElementInternalValidity = () => {
            this.setValidity(this.control.validity, this.control.validationMessage);
        };
        this.addEventListener("invalid", function (e) {
            e.preventDefault();
        });
    }
    setCharacterAt(i, c) {
        const str = this.control.value;
        this.control.value = str.substring(0, i) + c + str.substring(i + 1);
        this.control.setSelectionRange(i + 1, i + 1);
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.autocomplete) {
            this.autocompleteInput = this.parentElement.querySelector(`[autocomplete=${this.autocomplete}]`);
            const changeListener = () => {
                this.setAutocompleteValue();
                this.selectInputOnFocus = true;
                setTimeout(() => {
                    this.selectInputOnFocus = false;
                }, 300);
                this.autocompleteInput.removeEventListener("change", changeListener);
            };
            this.autocompleteInput?.addEventListener("change", changeListener);
            this.setAutocompleteValue();
        }
        this.valueChanged(this.value, this.value);
    }
    setAutocompleteValue() {
        if (this.autocompleteInput?.value) {
            this.value = this.autocompleteInput.value;
            this.error = this.control.validationMessage;
            this.classList.add(this.error ? "invalid" : "valid");
        }
    }
    valueChanged(previous, next) {
        super.valueChanged(previous, next);
        if (!this.control) {
            return;
        }
        if (this.autocompleteInput && this.autocompleteInput.value !== next) {
            this.autocompleteInput.value = next;
        }
        if (this.control.value !== next) {
            this.control.value = next;
        }
        this.setElementInternalValidity();
    }
    handleBlur() {
        if (this.inputPattern) {
            this.valueChanged(this.control.value, this.control.value);
            this.value = this.control.value;
        }
        if (!this.control.validity.valueMissing) {
            this.error = this.control.validationMessage;
            this.classList.add(this.error ? "invalid" : "valid");
        }
        else {
            this.classList.remove("invalid");
            this.classList.remove("valid");
        }
    }
    handleFocus() {
        if (this.selectInputOnFocus) {
            this.control.select();
        }
        this.error = "";
        this.classList.remove("invalid");
        this.classList.remove("valid");
    }
}
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", Object)
], M18TextField.prototype, "error", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18TextField.prototype, "validator", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18TextField.prototype, "autocomplete", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18TextField.prototype, "inputPattern", void 0);
exports.M18TextField = M18TextField;
const baseName = "text-field";
exports.m18TextField = M18TextField.compose({
    baseName,
    template: m18_text_field_template_1.M18TextFieldTemplate,
    styles: m18_text_field_styles_1.M18TextFieldStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
