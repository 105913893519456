"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioGroupTemplate = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const fast_web_utilities_1 = require("@microsoft/fast-web-utilities");
/**
 * The template for the {@link @microsoft/fast-foundation#RadioGroup} component.
 * @public
 */
exports.RadioGroupTemplate = (0, fast_element_1.html) `
  <template
    role="radiogroup"
    aria-disabled="${(x) => x.disabled}"
    aria-readonly="${(x) => x.readOnly}"
  >
    <slot name="label"></slot>
    <div
      class="positioning-region ${(x) => x.orientation === fast_web_utilities_1.Orientation.horizontal ? "horizontal" : "vertical"}"
      part="positioning-region"
    >
      <slot
        ${(0, fast_element_1.slotted)({
    property: "slottedRadioButtons",
    filter: (0, fast_element_1.elements)("[role=radio]"),
})}
      ></slot>
    </div>
  </template>
`;
