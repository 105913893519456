"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StealthButtonStyles = exports.OutlineButtonStyles = exports.LightweightButtonStyles = exports.HypertextNeutralStyles = exports.HypertextStyles = exports.AccentButtonStyles = exports.BaseButtonStyles = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const fast_web_utilities_1 = require("@microsoft/fast-web-utilities");
const fast_components_1 = require("@microsoft/fast-components");
/**
 * @internal
 */
exports.BaseButtonStyles = (0, fast_element_1.css) `
  ${(0, fast_foundation_1.display)("inline-flex")} :host {
    font-family: var(--body-font);
    outline: none;
    font-size: var(--type-ramp-base-font-size);
    line-height: var(--type-ramp-base-line-height);
    height: calc(
      (var(--base-height-multiplier) + var(--density)) * var(--design-unit) *
        1px
    );
    background-color: ${fast_components_1.neutralFillRest};
    color: ${fast_components_1.neutralForegroundRest};
    border-radius: calc(var(--control-corner-radius) * 1px);
    fill: currentcolor;
    cursor: pointer;
    transition: 0.3s all cubic-bezier(0.21, 0, 0, 1);
    box-shadow: 0px 8px 16px rgba(160, 164, 168, 0.16);
  }

  .icon {
    font-family: var(--icon-font-family);
  }

  .control {
    background: transparent;
    height: inherit;
    flex-grow: 1;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 calc((10 + (var(--design-unit) * 2 * var(--density))) * 1px);
    white-space: nowrap;
    outline: none;
    text-decoration: none;
    border: calc(var(--stroke-width) * 1px) solid transparent;
    color: inherit;
    border-radius: inherit;
    fill: inherit;
    cursor: inherit;
    font-weight: inherit;
    font-family: inherit;
  }

  :host(:hover) {
    background-color: ${fast_components_1.neutralFillHover};
    transform: scale(1.02);
  }

  :host(:active) {
    background-color: ${fast_components_1.neutralFillActive};
    transform: scale(1);
  }

  .control: ${fast_foundation_1.focusVisible} {
    border: calc(var(--stroke-width) * 1px) solid ${fast_components_1.neutralStrokeFocus};
    box-shadow: 0 0 0
      calc((var(--focus-stroke-width) - var(--stroke-width)) * 1px)
      ${fast_components_1.neutralStrokeFocus};
  }

  .control::-moz-focus-inner {
    border: 0;
  }

  :host([disabled]) {
    opacity: var(--disabled-opacity);
    background-color: ${fast_components_1.neutralFillRest};
    cursor: ${fast_foundation_1.disabledCursor};
  }

  .start,
  .end,
  ::slotted(svg) {
    ${
/* Glyph size and margin-left is temporary -
replace when adaptive typography is figured out */ ""} width: 16px;
    height: 16px;
  }

  .start {
    margin-inline-end: 11px;
  }

  .end {
    margin-inline-start: 11px;
  }
`;
/**
 * @internal
 */
exports.AccentButtonStyles = (0, fast_element_1.css) `
  :host([appearance="accent"]) {
    background: ${fast_components_1.accentFillRest};
    color: #fff;
  }

  :host([appearance="accent"]:hover) {
    background: ${fast_components_1.accentFillHover};
  }

  :host([appearance="accent"]:active) .control:active {
    background: ${fast_components_1.accentFillActive};
  }

  :host([appearance="accent"]) .control:${fast_foundation_1.focusVisible} {
    box-shadow: 0 0 0 calc(var(--focus-stroke-width) * 1px) inset
      ${fast_components_1.neutralStrokeFocus};
  }

  :host([appearance="accent"][disabled]) {
    background: ${fast_components_1.accentFillRest};
  }
`;
/**
 * @internal
 */
exports.HypertextStyles = (0, fast_element_1.css) `
  :host([appearance="hypertext"]) {
    font-size: inherit;
    line-height: inherit;
    height: auto;
    min-width: 0;
    background: transparent;
    box-shadow: none;
    color: ${fast_components_1.accentForegroundRest};
  }
  
  :host([appearance="hypertext"]:hover) {
    transform: none;
  }

  :host([appearance="hypertext"]) .control {
    display: inline;
    padding: 0;
    font-size: inherit;
    font-line-height: inherit;
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: 0;
    line-height: inherit;
  }
  :host a.control:not(:link) {
    background-color: transparent;
    cursor: default;
  }
  :host([appearance="hypertext"]) .control:link,
    background: transparent;
    color: ${fast_components_1.accentForegroundRest};
    border-bottom: calc(var(--stroke-width) * 1px) solid
      ${fast_components_1.accentForegroundRest};
  }
  :host([appearance="hypertext"]) .control:hover {
    border-bottom-color:${fast_components_1.accentForegroundRest};
  }
  :host([appearance="hypertext"]) .control:active {
    border: 1px solid transparent;
    border-bottom-color: ${fast_components_1.accentForegroundRest};
  }
  :host([appearance="hypertext"]) .control:${fast_foundation_1.focusVisible} {
    border-bottom: 1px solid
      ${fast_components_1.accentForegroundRest};
  }
`;
/**
 * @internal
 */
exports.HypertextNeutralStyles = (0, fast_element_1.css) `
  :host([appearance="hypertext"]-neutral) {
    font-size: inherit;
    line-height: inherit;
    height: auto;
    min-width: 0;
    background: transparent;
    box-shadow: none;
    color: inherit;
  }
  
  :host([appearance="hypertext"]-neutral:hover) {
    transform: none;
    color: ${fast_components_1.accentFillActive};
    text-decoration-line: underline;
  }

  :host([appearance="hypertext"]-neutral) .control {
    display: inline;
    padding: 0;
    font-size: inherit;
    font-line-height: inherit;
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: 0;
    line-height: inherit;
  }
  :host a.control:not(:link) {
    background-color: transparent;
    cursor: default;
  }
  :host([appearance="hypertext"]-neutral) .control:link,
    background: transparent;
    color: ${fast_components_1.accentForegroundRest};
    
  }
`;
/**
 * @internal
 */
exports.LightweightButtonStyles = (0, fast_element_1.css) `
  :host([appearance="lightweight"]) {
    background: transparent;
    color: ${fast_components_1.accentForegroundRest};
  }

  :host([appearance="lightweight"]) .control {
    padding: 0;
    height: initial;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  :host([appearance="lightweight"]:hover) {
    color: ${fast_components_1.accentForegroundHover};
  }

  :host([appearance="lightweight"]:active) {
    color: ${fast_components_1.accentForegroundActive};
  }

  :host([appearance="lightweight"]) .content {
    position: relative;
  }

  :host([appearance="lightweight"]) .content::before {
    content: "";
    display: block;
    height: calc(var(--stroke-width) * 1px);
    position: absolute;
    top: calc(1em + 4px);
    width: 100%;
  }

  :host([appearance="lightweight"]:hover) .content::before {
    background: ${fast_components_1.accentForegroundHover};
  }

  :host([appearance="lightweight"]:active) .content::before {
    background: ${fast_components_1.accentForegroundActive};
  }

  :host([appearance="lightweight"]) .control:${fast_foundation_1.focusVisible} .content::before {
    background: ${fast_components_1.neutralForegroundRest};
    height: calc(var(--focus-stroke-width) * 1px);
  }

  :host([appearance="lightweight"][disabled]) .content::before {
    background: transparent;
  }
`.withBehaviors((0, fast_foundation_1.forcedColorsStylesheetBehavior)((0, fast_element_1.css) `
      :host([appearance="lightweight"]:hover) .content::before {
        background: ${fast_web_utilities_1.SystemColors.LinkText};
      }
    `));
/**
 * @internal
 */
exports.OutlineButtonStyles = (0, fast_element_1.css) `
    :host([appearance="outline"]) {
        background: transparent;
        border-color: ${fast_components_1.accentFillRest};
    }

    :host([appearance="outline"]:hover) {
        border-color: ${fast_components_1.accentFillHover};
    }

    :host([appearance="outline"]:active) {
        border-color: ${fast_components_1.accentFillActive};
    }

    :host([appearance="outline"]) .control {
        border-color: inherit;
    }

    :host([appearance="outline"]) .control:${fast_foundation_1.focusVisible} {
        border: calc(var(--stroke-width) * 1px) solid ${fast_components_1.neutralStrokeFocus});
        box-shadow: 0 0 0 calc((var(--focus-stroke-width) - var(--stroke-width)) * 1px) ${fast_components_1.neutralStrokeFocus};
    }

    :host([appearance="outline"][disabled]) {
        border-color: ${fast_components_1.accentFillRest};
    }
`;
/**
 * @internal
 */
exports.StealthButtonStyles = (0, fast_element_1.css) `
  :host([appearance="stealth"]) {
    background: ${fast_components_1.neutralFillStealthRest};
  }

  :host([appearance="stealth"]:hover) {
    background: ${fast_components_1.neutralFillStealthHover};
  }

  :host([appearance="stealth"]:active) {
    background: ${fast_components_1.neutralFillStealthActive};
  }

  :host([appearance="stealth"][disabled]) {
    background: ${fast_components_1.neutralFillStealthRest};
  }
`;
