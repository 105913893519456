"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18TextArea = exports.M18TextArea = void 0;
const fast_components_1 = require("@microsoft/fast-components");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const helpers_1 = require("./helpers");
class M18TextArea extends fast_components_1.TextArea {
    constructor() {
        super();
        this.addEventListener("invalid", function (e) {
            e.preventDefault();
        });
    }
    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => {
            this.setValidity(this.control.validity, this.control.validationMessage);
        });
        this.valueChanged(this.value, this.value);
    }
    valueChanged(previous, next) {
        super.valueChanged(previous, next);
        if (!this.control) {
            return;
        }
        this.setValidity(this.control.validity, this.control.validationMessage);
    }
}
exports.M18TextArea = M18TextArea;
const baseName = "text-area";
exports.m18TextArea = M18TextArea.compose({
    baseName,
    template: (0, helpers_1.template)(fast_foundation_1.textAreaTemplate, baseName),
    styles: (0, helpers_1.styles)(fast_components_1.textAreaStyles, baseName),
    shadowOptions: {
        delegatesFocus: true,
    },
});
