"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const otpFill = () => {
    const location = new URL(document.location.toString().replace("&amp;", "&"));
    const key = location.searchParams.get("_key");
    const otp = location.searchParams.get("_otp");
    const keyValid = /^([a-fA-F0-9]{8}-(?:[a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}){1}$/.test(key);
    if (keyValid) {
        document
            .querySelectorAll("input[name=_key]")
            .forEach((element) => {
            element.value = key;
        });
    }
    const otpValid = /^([0-9]{6})$/.test(otp);
    if (otpValid && keyValid) {
        const form = document.querySelector("[otp-verify]");
        if (form) {
            const otpInput = form.querySelector("[name=otp]");
            otpInput.setAttribute("disabled", "true");
            document
                .querySelectorAll(".hide-otp-fill")
                .forEach((el) => el.style && (el.style.display = "none"));
            if (otpInput) {
                otpInput.value = otp;
            }
        }
    }
};
exports.default = otpFill;
