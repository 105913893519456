"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerServiceWorker = void 0;
let serviceWorkerRegistered = false;
const registerServiceWorker = () => {
    if (serviceWorkerRegistered) {
        return;
    }
    serviceWorkerRegistered = true;
    if ("serviceWorker" in navigator) {
        console.info("register: sercice worker");
        navigator.serviceWorker
            .register("/static/js/service.js", { scope: "/" })
            .then((reg) => {
            navigator.serviceWorker.addEventListener("message", (event) => {
                if (event.data.type === "log") {
                    console.log("sw", event.data);
                }
                if (event.data.type === "session-update") {
                    console.info("reload: session-update");
                    document.location.reload();
                }
            });
            navigator.serviceWorker.controller.postMessage({
                type: "refresh-session",
            });
            setInterval(() => {
                navigator.serviceWorker.controller.postMessage({
                    type: "ping",
                });
            }, 5000);
        })
            .catch((error) => {
            console.error("Service worker registration failed with " + error);
        });
    }
};
exports.registerServiceWorker = registerServiceWorker;
