"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18PinField = exports.M18PinField = void 0;
const tslib_1 = require("tslib");
const fast_element_1 = require("@microsoft/fast-element");
const m18_pin_field_template_1 = require("./m18-pin-field.template");
const m18_pin_field_styles_1 = require("./m18-pin-field.styles");
const fast_foundation_1 = require("@microsoft/fast-foundation");
class M18PinField extends fast_foundation_1.TextField {
    constructor() {
        super();
        this.error = "";
        this.disabledChanged = (_previous, current) => {
            [...new Array(this.length)].forEach((_, i) => {
                const field = this.getField(i);
                if (field) {
                    field.disabled = !!current;
                }
            });
        };
        this.handlePaste = (e) => {
            let clipboardData = e.clipboardData || window["clipboardData"];
            if (clipboardData) {
                const data = clipboardData.getData("Text");
                if (!data) {
                    return;
                }
                this.setValue(data);
            }
            e.stopPropagation();
            e.preventDefault();
        };
        this.keydownHandler = (e) => {
            const currentIndex = this.getFieldIndex(e.target);
            if (currentIndex === null) {
                return true;
            }
            const valid = this.isValidInput(e.key);
            switch (true) {
                case e.code === "Tab" && !e.shiftKey:
                case e.code === "ArrowRight":
                    return !this.focusField(currentIndex + 1);
                case e.code === "Tab" && e.shiftKey:
                case e.code === "ArrowLeft":
                    return !this.focusField(currentIndex - 1);
                case e.code === "KeyV":
                    return true;
                default:
                    this.computeFormValue();
                    return valid;
            }
        };
        this.inputHandler = (e) => {
            let char = e.target.value;
            if (char.length > 1) {
                char = char.substr(0, 1);
                e.target.value = char;
            }
            if (this.isValidInput(char)) {
                this.focusField(this.getFieldIndex(e.target) + 1);
            }
            else {
                e.target.value = "";
            }
            return true;
        };
        this.addEventListener("invalid", function (e) {
            e.preventDefault();
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this.form.addEventListener("reset", () => this.reset());
        this.valueChanged(this.value, this.value);
    }
    reset() {
        for (let i = 0; i < this.length; i++) {
            const field = this.getField(i);
            if (field) {
                field.value = "";
            }
        }
        this.computeFormValue();
        this.focusField(0);
    }
    computeFormValue() {
        setTimeout(() => {
            const value = new Array(this.length)
                .fill(null)
                .map((_, i) => this.getField(i).value)
                .reduce((res, value) => res + (value?.length ? value : " "), "");
            if (value.replace(/ /, "").length === this.length) {
                this.control.value = value;
            }
            else {
                this.control.value = null;
            }
            this.handleTextInput();
        }, 0);
    }
    setValue(value) {
        this.reset();
        Array.from(value.trim()).forEach((char, i) => {
            const field = this.getField(i);
            if (field) {
                field.value = this.isValidInput(char.toString()) ? char.toString() : "";
            }
        });
        this.computeFormValue();
    }
    getField(index) {
        return this.shadowRoot.querySelector(`input[name=field-${index}]`);
    }
    getFieldIndex(field) {
        const value = field.getAttribute("name");
        if (!value || !value.match(/field-[0-9]+/)) {
            return null;
        }
        return ~~value.replace("field-", "");
    }
    focusField(index) {
        const field = this.getField(index);
        if (field) {
            field.focus();
            return true;
        }
        return false;
    }
    isValidInput(char) {
        if (this.type === "number") {
            return !!char.match(/[0-9]/);
        }
        return true;
    }
    valueChanged(previous, next) {
        super.valueChanged(previous, next);
        if (!this.control) {
            return;
        }
        [...new Array(this.length)].forEach((_, i) => {
            const field = this.getField(i);
            if (field) {
                field.value = next[i] || "";
            }
        });
        if (this.value?.replace(/ /, "").length === this.length) {
            this.setValidity({ valueMissing: false }, "");
            this.control.setCustomValidity("");
        }
        else {
            this.setValidity({ valueMissing: true }, "Value is missing");
            this.control.setCustomValidity("Value is missing");
        }
        this.setValidity(this.control.validity, this.control.validationMessage ||
            (this.control.validity.customError ? "Value is missing" : ""));
        this.$emit("change");
        if (this.submit && this.value) {
            const defaultButton = this.form.querySelector("[type=submit]");
            defaultButton?.click();
        }
    }
    handleInputBlur() {
        this.computeFormValue();
    }
    handleBlur() {
        if (!this.control.validity.valueMissing) {
            this.error = this.validationMessage;
            this.classList.add(this.error ? "invalid" : "valid");
        }
        else {
            this.classList.remove("invalid");
            this.classList.remove("valid");
        }
    }
    handleFocus(e) {
        const target = e.target;
        setTimeout(() => {
            target.setSelectionRange?.(0, 1);
        }, 0);
    }
}
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18PinField.prototype, "error", void 0);
tslib_1.__decorate([
    (0, fast_element_1.attr)({ converter: fast_element_1.booleanConverter }),
    tslib_1.__metadata("design:type", Boolean)
], M18PinField.prototype, "submit", void 0);
tslib_1.__decorate([
    (0, fast_element_1.attr)({ converter: fast_element_1.nullableNumberConverter }),
    tslib_1.__metadata("design:type", Number)
], M18PinField.prototype, "length", void 0);
tslib_1.__decorate([
    fast_element_1.attr,
    tslib_1.__metadata("design:type", String)
], M18PinField.prototype, "validator", void 0);
exports.M18PinField = M18PinField;
const baseName = "pin-field";
exports.m18PinField = M18PinField.compose({
    baseName,
    template: m18_pin_field_template_1.M18PinFieldTemplate,
    styles: m18_pin_field_styles_1.M18PinFieldStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
