"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18LangStyles = void 0;
const fast_components_1 = require("@microsoft/fast-components");
const fast_element_1 = require("@microsoft/fast-element");
const fast_foundation_1 = require("@microsoft/fast-foundation");
exports.M18LangStyles = (0, fast_element_1.css) `
  ${(0, fast_foundation_1.display)("inline-block")} :host {
    font-family: inherit;
    outline: none;
    user-select: none;
    position: relative;
    line-height: inherit;
    color: inherit;
  }

  .wrapper {
    position: relative;
  }
  .wrapper:before {
    position: absolute;
    content: "";
    top: -50px;
    left: -20px;
    height: 100px;
    width: 200%;
    display: block;
  }

  .item--selected i {
    opacity: 1;
  }

  .menu {
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 5px;
    border-radius: calc(var(--control-corner-radius) * 1px);
    overflow: hidden;
  }

  .menu.menu--open li {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: white;
  }

  li {
    text-transform: uppercase;
    font-weight: 500;
    font-size: var(--type-ramp-minus-1-font-size);
    display: none;
    margin: 0;
    line-height: 2.2rem;
    cursor: pointer;
    padding: 0.4rem 2rem 0.4rem 1.5rem;
    border-bottom: 1px solid var(--neutral-fill-hover);
  }

  li:hover {
    color: white;
    background-color: ${fast_components_1.accentFillActive};
  }

  li:last-child {
    border-bottom: none;
  }

  i {
    opacity: 0;
    color: var(--accent-fill-active);
    font-family: "m18";
    margin-right: 0.7rem;
    font-style: normal;
    font-size: var(--type-ramp-plus-1-font-size);
    line-height: var(--type-ramp-base-line-height);
  }

  li:hover i {
    color: white;
  }

  .touch-device .menu {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    display: flex;
    border-radius: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .touch-device ul {
    border-radius: calc(var(--control-corner-radius) * 1px);
    margin-top: 0;
  }

  .touch-device li {
    display: block;
  }
`;
