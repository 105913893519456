"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18Anchor = void 0;
const fast_components_1 = require("@microsoft/fast-components");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const m18_anchor_styles_1 = require("./m18-anchor.styles");
const helpers_1 = require("../helpers");
const baseName = "anchor";
exports.m18Anchor = fast_components_1.Anchor.compose({
    baseName,
    template: (0, helpers_1.template)(fast_foundation_1.anchorTemplate, baseName),
    styles: m18_anchor_styles_1.M18AnchorStyles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
