"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18PinFieldStyles = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const fast_web_utilities_1 = require("@microsoft/fast-web-utilities");
const fast_components_1 = require("@microsoft/fast-components");
const size_1 = require("../styles/size");
exports.M18PinFieldStyles = (0, fast_element_1.css) `
  ${(0, fast_foundation_1.display)("inline-block")} :host {
    font-family: var(--body-font);
    font-weight: 500;
    outline: none;
    user-select: none;
  }

  .controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .control-4 {
    width: calc(100% / 4 - 3em / 4);
  }
  .control-5 {
    width: calc(100% / 5 - 4em / 5);
  }
  .control-6 {
    width: calc(100% / 6 - 5em / 6);
  }
  .control {
    box-sizing: border-box;
    display: flex;
    position: relative;
    color: ${fast_components_1.neutralForegroundRest};
    background: ${fast_components_1.neutralFillInputRest};
    border-radius: calc(var(--control-corner-radius) * 1px);
    border: calc(var(--stroke-width) * 1px) solid transparent;
    height: calc(${size_1.heightNumber} * 1.5px);
    max-height: calc(3 * var(--font-size));
  }

  .control > input {
    // -webkit-transform: translate(0);
    -webkit-appearance: none;
    background: transparent;
    text-align: center;
    color: inherit;

    height: calc(var(--font-size) + 1rem);
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    font-family: inherit;
    font-weight: inherit;
    padding: 0 calc(var(--design-unit) * 1px);
    font-size: var(--font-size);
    line-height: var(--font---size);
  }
  :host .valid,
  .root .invalid {
    font-family: var(--icon-font-family);
    font-weight: 400;
    display: none;
    margin: auto;
  }
  :host .validation {
    margin: auto 10px;
  }

  :host(.valid) .valid {
    color: ${fast_components_1.accentFillRest};
    display: inline-block;
  }

  [part="error"] {
    color: var(--error-color);
    font-size: var(--type-ramp-minus-1-font-size);
    font-weight: 400;
    float: right;
    line-height: 1rem;
    margin-bottom: -1rem;
    margin-left: 3rem;
  }
  :host(.invalid) .invalid {
    color: var(--error-color);
    display: inline-block;
  }

  :host(.invalid) .root {
    border-color: var(--error-color);
  }

  :host(.valid) .root {
    border-color: ${fast_components_1.accentFillRest};
  }

  .control > input::placeholder {
    opacity: 0.5;
    line-height: normal;
  }

  .control:hover,
    .control:${fast_foundation_1.focusVisible},
    .control:disabled,
    .control:active {
    outline: none;
  }

  .label {
    display: block;
    color: ${fast_components_1.neutralForegroundRest};
    cursor: pointer;
    font-size: var(--type-ramp-minus-1-font-size);
    line-height: var(--type-ramp-minus-1-line-height);
    font-weight: 400;
    margin-bottom: 4px;
  }

  .label__hidden {
    display: none;
    visibility: hidden;
  }

  .start,
  .end {
    ${
/* Glyph size and margin-left is temporary -
replace when adaptive typography is figured out */ ""} width: 16px;
    height: 16px;
    margin: auto;
    fill: currentcolor;
  }

  .start {
    margin-inline-start: 11px;
  }

  .end {
    margin-inline-end: 11px;
  }

  input:required,
  input:invalid,
  input:focus,
  input:focus-within,
  input:active {
    box-shadow: none;
    outline: none;
    border: none;
  }
  :host(.disabled) .label,
  :host(.readonly) .label,
  :host(.readonly) .control,
  :host(.disabled) .control {
    cursor: ${fast_foundation_1.disabledCursor};
  }

  :host(.disabled) {
    opacity: var(--disabled-opacity);
  }
`.withBehaviors((0, fast_foundation_1.forcedColorsStylesheetBehavior)((0, fast_element_1.css) `
      .root,
      :host(.filled) .root {
        forced-color-adjust: none;
        background: ${fast_web_utilities_1.SystemColors.Field};
        border-color: ${fast_web_utilities_1.SystemColors.FieldText};
      }
      :host(:hover:not(.disabled)) .root,
      :host(.filled:hover:not(.disabled)) .root,
      :host(.filled:hover) .root {
        background: ${fast_web_utilities_1.SystemColors.Field};
        border-color: ${fast_web_utilities_1.SystemColors.Highlight};
      }
      .start,
      .end {
        fill: currentcolor;
      }
      :host(.disabled) {
        opacity: 1;
      }
      :host(.disabled) .root,
      :host(.filled:hover.disabled) .root {
        border-color: ${fast_web_utilities_1.SystemColors.GrayText};
        background: ${fast_web_utilities_1.SystemColors.Field};
      }
      :host(:focus-within:enabled) .root {
        border-color: ${fast_web_utilities_1.SystemColors.Highlight};
        box-shadow: 0 0 0 1px ${fast_web_utilities_1.SystemColors.Highlight} inset;
      }
      input::placeholder {
        color: ${fast_web_utilities_1.SystemColors.GrayText};
      }
    `));
