"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.m18RadioGroup = exports.M18RadioGroup = void 0;
const tslib_1 = require("tslib");
const fast_element_1 = require("@microsoft/fast-element");
const fast_components_1 = require("@microsoft/fast-components");
const radio_group_template_1 = require("./radio-group.template");
const helpers_1 = require("../helpers");
class M18RadioGroup extends fast_components_1.RadioGroup {
    constructor() {
        super();
        this.addEventListener("change", this.radioChangeHandlerBis);
    }
    radioChangeHandlerBis(e) {
        if (this.required !== void 0) {
            if (!e.target.checked) {
                if (this.slottedRadioButtons.every((el) => el.checked === false)) {
                    e.target.checked = true;
                }
            }
        }
    }
}
tslib_1.__decorate([
    (0, fast_element_1.attr)(),
    tslib_1.__metadata("design:type", Boolean)
], M18RadioGroup.prototype, "required", void 0);
exports.M18RadioGroup = M18RadioGroup;
const baseName = "radio-group";
exports.m18RadioGroup = M18RadioGroup.compose({
    baseName,
    template: radio_group_template_1.RadioGroupTemplate,
    styles: (0, helpers_1.styles)(fast_components_1.radioGroupStyles, baseName),
    shadowOptions: {
        delegatesFocus: true,
    },
});
