"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18FederationStyles = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const styles_1 = require("../styles");
const fast_foundation_1 = require("@microsoft/fast-foundation");
exports.M18FederationStyles = (0, fast_element_1.css) `
  ${styles_1.BaseButtonStyles}
  :host {
    background: var(--provider-fill-rest);
    color: var(--font-color);
    text-transform: uppercase;
    font-size: var(--type-ramp-minus-1-font-size);
    font-weight: 600;
    letter-spacing: 0.12em;
    line-height: var(--type-ramp-plus-2-line-height);
    vertical-align: middle;
    width: 100%;
  }

  :host .icon {
    font-size: var(--type-ramp-plus-2-font-size);
    line-height: var(--type-ramp-plus-2-line-height);
    vertical-align: middle;
    padding-right: 0.3em;
  }

  :host(:hover) {
    background: var(--provider-fill-hover);
  }

  :host(:active) .control:active {
    background: var(--provider-fill-active);
  }

  :host() .control:${fast_foundation_1.focusVisible} {
    box-shadow: 0 0 0 calc(var(--focus-stroke-width) * 1px) inset
      var(--provider-neutral-focus-inner);
  }

  :host(.disabled) {
    background: var(--provider-fill-rest);
  }
`;
