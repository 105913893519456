"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.M18TextFieldTemplate = void 0;
const fast_element_1 = require("@microsoft/fast-element");
const fast_foundation_1 = require("@microsoft/fast-foundation");
const M18TextFieldTemplate = (context, definition) => (0, fast_element_1.html) `
  <template
    class="
            ${(x) => (x.readOnly ? "readonly" : "")}
        "
  >
    <label
      part="label"
      for="control"
      class="${(x) => x.defaultSlottedNodes && x.defaultSlottedNodes.length
    ? "label"
    : "label label__hidden"}"
    >
      <slot
        ${(0, fast_element_1.slotted)({
    property: "defaultSlottedNodes",
    filter: fast_foundation_1.whitespaceFilter,
})}
      ></slot>
    </label>
    <div class="root" part="root">
      ${(0, fast_foundation_1.startSlotTemplate)(context, definition)}
      <input
        class="control"
        part="control"
        id="control"
        autocorrect="off"
        autocapitalize="none"
        @keypress="${(x, c) => x.keypressHandler(c.event)}"
        @keydown="${(x, c) => x.keydownHandler(c.event)}"
        @input="${(x, c) => x.handleTextInput(c.event)}"
        @change="${(x) => x.handleChange()}"
        @blur="${(x) => x.handleBlur()}"
        @focus="${(x) => x.handleFocus()}"
        placeholder="${(x) => x.placeholder}"
        ?required="${(x) => x.required}"
        ?disabled="${(x) => x.disabled}"
        ?readonly="${(x) => x.readOnly}"
        :value="${(x) => x.value}"
        type="${(x) => x.type}"
        aria-atomic="${(x) => x.ariaAtomic}"
        aria-busy="${(x) => x.ariaBusy}"
        aria-controls="${(x) => x.ariaControls}"
        aria-current="${(x) => x.ariaCurrent}"
        aria-describedBy="${(x) => x.ariaDescribedby}"
        aria-details="${(x) => x.ariaDetails}"
        aria-disabled="${(x) => x.ariaDisabled}"
        aria-errormessage="${(x) => x.ariaErrormessage}"
        aria-flowto="${(x) => x.ariaFlowto}"
        aria-haspopup="${(x) => x.ariaHaspopup}"
        aria-hidden="${(x) => x.ariaHidden}"
        aria-invalid="${(x) => x.ariaInvalid}"
        aria-keyshortcuts="${(x) => x.ariaKeyshortcuts}"
        aria-label="${(x) => x.ariaLabel}"
        aria-labelledby="${(x) => x.ariaLabelledby}"
        aria-live="${(x) => x.ariaLive}"
        aria-owns="${(x) => x.ariaOwns}"
        aria-relevant="${(x) => x.ariaRelevant}"
        aria-roledescription="${(x) => x.ariaRoledescription}"
        ${(0, fast_element_1.ref)("control")}
      />
      <span class="validation">
        <span class="invalid">&#xe801;</span>
        <span class="valid">&#xe800;</span>
      </span>
      ${(0, fast_foundation_1.endSlotTemplate)(context, definition)}
    </div>
    <span part="error" :innerText="${(x) => x.error}"></span>
  </template>
`;
exports.M18TextFieldTemplate = M18TextFieldTemplate;
